@if (workers?.length > 0) {
  <form #workerForm="ngForm">
    <!-- Show this section if worker length is greater than 10 -->
    @if (workers?.length > 10) {
      <div class="row">
        <div class="col-md-12">
          <label>
            <app-csw-text
              [text]="widgetResourceHeadingTemplate | translation : 'template_content' : lang"
              customClass="fw-600"
            />
          </label>
          <ng-select
            id="store"
            name="store"
            placeholder=""
            [multiple]="false"
            [(ngModel)]="selectedWorkerId"
            (change)="onWorkerChange()"
            [required]="true"
            #workerControl="ngModel"
            [ngClass]="{
              'is-invalid': workerControl.touched && workerControl.invalid
            }"
          >
            <ng-option [value]="0">
              <div class="d-flex align-items-center">
                <div class="avatar">
                  <img
                    class="default-avatar rounded-circle"
                    src="{{ baseUrl }}/img/sample-avatar.png"
                  />
                </div>
                <div class="description">
                  <div class="cbw-title">Anyone</div>
                </div>
              </div>
            </ng-option>
            @for (worker of workers; track worker) {
              @if (worker.id != null) {
                <ng-option [value]="worker.id">
                  <div class="d-flex align-items-center">
                    <div class="avatar">
                      @if (worker.avatar !== null) {
                        <img
                          #image
                          class="resource-avatar rounded-circle"
                          src="{{ workerAvatarBaseUrl }}{{ worker.uuid }}/thumb_{{ worker.avatar }}"
                          (error)="image.src = baseUrl + '/img/sample-avatar.png'"
                        />
                      }
                      @if (worker.avatar === null) {
                        <img
                          class="default-avatar rounded-circle"
                          src="{{ baseUrl }}/img/sample-avatar.png"
                        />
                      }
                    </div>
                    <div class="description">
                      <div class="cbw-title">
                        @if (worker.is_resource === 0) {
                          {{ worker.resource_name }}
                        }
                        @if (worker.is_resource === 1) {
                          @if (worker.is_multi_language === 1) {
                            {{ worker | translation : "booking_label" : lang }}
                          }
                          @if (worker.is_multi_language === 0) {
                            {{ worker.resource_name }}
                          }
                        }
                      </div>
                      @if (worker.is_resource === 0) {
                        <div class="cbw-function">
                          @if (worker.is_multi_language === 1) {
                            {{ worker | translation : "job_title" : lang }}
                          }
                          @if (worker.is_multi_language === 0) {
                            {{ worker.job_title }}
                          }
                        </div>
                      }
                      @if (worker.is_resource !== 0) {
                        <div class="cbw-function">
                          @if (worker.is_multi_language === 1) {
                            {{ worker | translation : "description" : lang }}
                          }
                          @if (worker.is_multi_language === 0) {
                            {{ worker.description }}
                          }
                        </div>
                      }
                    </div>
                  </div>
                </ng-option>
              }
            }
          </ng-select>
          @if (workerControl.touched && !workerControl.valid) {
            <div class="text-danger">
              @if (workerControl.errors.required) {
                <div>This field is required.</div>
              }
            </div>
          }
        </div>
      </div>
    }
    <!-- Show this section if worker length is less than 10 -->
    @if (workers?.length > 0 && workers?.length <= 10) {
      <div>
        <app-csw-text
          [text]="widgetResourceHeadingTemplate | translation : 'template_content' : lang"
          customClass="fw-600"
        />
      </div>
      <div class="row">
        @if (workers?.length > 1) {
          <div
            class="worker-slot-card col-md-6 col-lg-4 mt-3"
            (click)="selectedWorkerId = 0; onWorkerChange()"
          >
            <app-info-card
              [showCardBorder]="true"
              [showShadow]="false"
              [showBody]="true"
              [isSelected]="selectedWorkerId === 0"
              [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
            >
              <div cardBody>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="avatar">
                      <img
                        class="default-avatar rounded-circle"
                        src="{{ baseUrl }}/img/sample-avatar.png"
                      />
                    </div>
                    <div class="description">
                      <div class="cbw-title">
                        {{ widgetAnyoneLabelTemplate | translation : "template_content" : lang }}
                      </div>
                      <div class="cbw-function">
                        {{ widgetAnyoneDescTemplate | translation : "template_content" : lang }}
                      </div>
                    </div>
                  </div>
                  <div class="next-arrow-container">
                    @if (selectedWorkerId === 0) {
                      <span (click)="next()">
                        <img
                          src="https://my.calenso.com/images/next_icon_widget.svg"
                          width="26px"
                        />
                      </span>
                    }
                  </div>
                </div>
              </div>
            </app-info-card>
          </div>
        }
        @for (worker of workers; track worker) {
          @if (worker.id != null) {
            <div
              class="worker-slot-card mt-3 col-md-6 col-lg-4"
              (click)="selectedWorkerId = worker.id; onWorkerChange()"
            >
              <app-info-card
                [showCardBorder]="true"
                [showShadow]="false"
                [showBody]="true"
                [isSelected]="selectedWorkerId === worker.id"
                [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
              >
                <div cardBody>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                      <div class="avatar">
                        @if (worker.avatar !== null) {
                          <img
                            alt=""
                            #image
                            class="resource-avatar rounded-circle"
                            src="{{ workerAvatarBaseUrl }}{{ worker.uuid }}/thumb_{{ worker.avatar }}"
                            (error)="image.src = baseUrl + '/img/sample-avatar.png'"
                          />
                        }
                        @if (worker.avatar === null) {
                          <img
                            alt=""
                            class="default-avatar rounded-circle"
                            src="{{ baseUrl }}/img/sample-avatar.png"
                          />
                        }
                      </div>
                      <div class="description">
                        <div class="cbw-title">
                          @if (worker.is_resource === 0) {
                            {{ worker.resource_name }}
                          }
                          @if (worker.is_resource === 1) {
                            @if (worker.is_multi_language === 1) {
                              {{ worker | translation : "booking_label" : lang }}
                            }
                            @if (worker.is_multi_language === 0) {
                              {{ worker.resource_name }}
                            }
                          }
                        </div>
                        @if (worker.is_resource === 0) {
                          <div class="cbw-function">
                            @if (worker.is_multi_language === 1) {
                              {{ worker | translation : "job_title" : lang }}
                            }
                            @if (worker.is_multi_language === 0) {
                              {{ worker.job_title }}
                            }
                          </div>
                        }
                        @if (worker.is_resource !== 0) {
                          <div class="cbw-function">
                            @if (worker.is_multi_language === 1) {
                              {{ worker | translation : "description" : lang }}
                            }
                            @if (worker.is_multi_language === 0) {
                              {{ worker.description }}
                            }
                          </div>
                        }
                      </div>
                    </div>
                    <div class="next-arrow-container">
                      @if (selectedWorkerId === worker.id) {
                        <span (click)="next()">
                          <img
                            alt=""
                            src="https://my.calenso.com/images/next_icon_widget.svg"
                            width="26px"
                          />
                        </span>
                      }
                    </div>
                  </div>
                </div>
              </app-info-card>
            </div>
          }
        }
      </div>
    }
    <div class="d-flex justify-content-end mt-4 mb-4 cbw-custom-navigation">
      <div class="me-2">
        <app-button
          id="{{ 'lead-workers-selection-previous-btn' }}"
          (clickEvent)="previous()"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
          <span class="custom-button-icon back"></span>
          {{ "form_fields.previous" | translate }}
        </app-button>
      </div>
      <div>
        <app-button
          id="{{ 'lead-workers-selection-next-btn' }}"
          (clickEvent)="next()"
          [isDisabled]="selectedWorkerId === null || selectedWorkerId === undefined"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
          {{ "form_fields.next" | translate }}
          <span class="custom-button-icon next"></span>
        </app-button>
      </div>
    </div>
  </form>
}
